html {
  font-size: calc(1em * 0.625);
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

.t-description {
  color: $c-skin;
}

.t-h1-alt {
  @extend %fw-normal;
  // prettier-ignore
  @include fluid(font-size,(xxs: 48px, xxl: 185px));

  display: block;
  color: $c-green;
  font-family: $ff-alt;
  font-style: italic;
  line-height: 1;
  text-transform: uppercase;
}

.t-h2,
[class*='t-h2'] {
  @extend %fw-normal;
  // prettier-ignore
  @include fluid(font-size,(xxs: 36px, xxl: 96px));
  // prettier-ignore
  @include fluid(letter-spacing,(xxs: 0.7px, xxl: 1.9px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 40px, xxl: 96px));

  color: $c-green;
  font-family: $ff-alt;

  &[class*='-alt'] {
    font-style: italic;
    text-transform: uppercase;

    strong {
      font-style: normal;
    }
  }
}

.h3 {
  // prettier-ignore
  @include fluid(font-size,(xxs: 24px, xxl: 48px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 28px, xxl: 52px));

  font-family: $ff-alt;
}

.homepage-title {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 55px, xxl: 75px));
}

.internal-page-title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 48px, xxl: 160px));
  margin-bottom: 0.5em;
  font-family: $ff-alt;
  text-align: center;
  line-height: 1;
  font-weight: 400;
  color: $c-green;
}

.display-title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 35px, xxl: 130px));

  color: $c-green;
  font-family: $ff-alt;
  line-height: 0.75;
  padding-top: 0.075em; // Add small pt to compensate because small line-height move text up
  text-transform: uppercase;
  font-weight: 400;

  &[data-text-splitter] {
    // When text splitting, we'll add padding to to the individual lines instead
    padding-top: 0;

    .line {
      padding-top: 0.075em;
      margin-top: -0.075em;
    }
  }
}

.lead-big {
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, xxl: 36px));
  line-height: 1.33;
  font-family: $ff-alt;
}

.lead {
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 24px));
  line-height: 1.5;
  font-family: $ff-alt;
}

.body {
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xxl: 14px));
  line-height: 1.714;
}

.tiny {
  // prettier-ignore
  font-size: 14px;
  font-weight: 500;
  line-height: 1.428;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.btn-label {
  // prettier-ignore
  @include fluid(font-size, (xxs: 10px, xxl: 12px));
  line-height: 1.714;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.5;
}
