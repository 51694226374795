
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































































































































.mega,
[class*='mega--'] {
}

.mega__links {
  display: flex;
  align-items: center;

  .home & {
    opacity: 0;
  }
}

.mega__links__contact {
  // prettier-ignore
  @include fluid(margin-right,(xxs: 20px, xxl: 40px));

  svg {
    width: auto;
    height: 1.4rem;
    fill: $c-brown;
    transition: fill 0.15s;
  }

  &:hover {
    svg {
      fill: $c-skin;
    }
  }
}

.mega__links__trigger {
  margin-bottom: 2px;
  cursor: pointer;
  background: transparent;
  border: 0;

  svg {
    width: 3rem;
    stroke: $c-brown;
    transition: stroke 0.15s;
  }

  line {
    transform-origin: center;
    transform-box: fill-box;
    transition: transform 0.2s;
  }

  .is-open & {
    line:first-child {
      transform: translateY(3px) rotate(45deg);
    }

    line:last-child {
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  &:hover {
    svg {
      stroke: $c-skin;
    }
  }
}

.mega__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;

  .is-open & {
    opacity: 1;
    pointer-events: auto;
  }

  @include mq($until: l) {
    top: $header-height;
    height: calc(100% - #{$header-height});
  }

  @include mq(m, l) {
    top: $header-height-large;
    height: calc(100% - #{$header-height-large});
  }
}

.mega__menu__bg {
  @include get-all-space;

  background: rgba($c-black, 0.8);
}

.mega__menu__content {
  @include scrollbars;

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 15rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  height: 100%;
  padding: 3rem;
  background: $c-white;
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 150% 100%);

  @include mq(m) {
    padding: 5rem col(2);
  }

  @include mq(l) {
    width: col(10);
    padding: 5rem col(1);
  }

  @include mq(xxl) {
    width: col(8);
    padding: 5rem col(1);
  }

  @media (max-height: 40rem) {
    justify-content: space-between;
    gap: 5rem;
  }
}

.mega__menu__close {
  position: absolute;
  right: 3rem;
  display: flex;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transform: translateY(-20%);

  svg {
    display: block;
    width: auto;
    fill: $c-skin;
    transition: fill 0.15s;
  }

  &:hover {
    svg {
      fill: $c-brown;
    }
  }

  @include mq($until: l) {
    display: none;
  }
}

.mega__menu__links__item {
  display: block;
  color: $c-brown;
  text-decoration: none;
  transition: color 0.15s;

  &.title {
    color: $c-skin;
    pointer-events: none;
    font-style: italic;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    color: $c-skin;
  }
}

.mega__menu__places {
  margin-top: auto;
}

.mega__menu__places__item {
  display: flex;
  height: 9rem;
  border: 1px solid $c-beige;
  transition: border 0.15s;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    border: 1px solid $c-skin;
  }
}

.mega__menu__places__item__picture {
  position: relative;
  flex-shrink: 0;
  width: 9rem;

  ::v-deep img {
    @include image-fit;
  }
}

.mega__menu__places__item__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
  padding: 1.5rem 0;

  svg {
    width: auto;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
    fill: $c-brown;
  }
}
