@font-face {
  font-family: 'Freight';
  src: url('../assets/fonts/freight/FreightDispBook.woff2') format('woff2'),
    url('../assets/fonts/freight/FreightDispBook.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Freight';
  src: url('../assets/fonts/freight/FreightDispBookItalic.woff2')
      format('woff2'),
    url('../assets/fonts/freight/FreightDispBookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Freight';
  src: url('../assets/fonts/freight/freightdisplightitalic-webfont.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Freight';
  src: url('../assets/fonts/freight/FreightDispMedium.woff2') format('woff2'),
    url('../assets/fonts/freight/FreightDispMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
