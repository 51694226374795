@use "sass:math";

$gutter: 0;
$cols: 24;

/* No IE support */
:root {
  --gutter-width: #{$gutter};
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));

  @each $mq, $w in $mq-breakpoints {
    $prop: #{$mq}-min;
    $em: strip-unit(mq-px2em($w));

    /* stylelint-disable custom-property-pattern, function-whitespace-after */
    --#{$prop}: #{$em};
    --screen-#{$prop}: var(--#{$prop}) em;
    /* stylelint-enable custom-property-pattern, function-whitespace-after */
  }
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  box-sizing: border-box;
  // margin-right: var(--gutter-compensation, -0.5rem);
  // margin-left: var(--gutter-compensation, -0.5rem);
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

@mixin print($mq) {
  // Common styles
  $selector: '.col-#{$mq},';

  @for $i from 1 through $cols {
    $selector: $selector + '.col-#{$mq}-' + $i + ',';
  }

  @for $i from 0 through $cols {
    $selector: $selector + '.col-#{$mq}-offset-' + $i + ',';
  }

  /* stylelint-disable-next-line order/order */
  #{$selector} {
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }

  // Cols width
  @for $i from 0 through $cols {
    @if $i == 0 {
      .col-#{$mq} {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }
    } @else {
      .col-#{$mq}-#{$i} {
        $w: math.div(100%, $cols) * $i;

        flex-basis: $w;
        max-width: $w;
      }
    }
  }

  // cols offset
  @for $i from 0 through ($cols - 1) {
    $w: if($i == 0, 0, math.div(100%, $cols) * $i);

    .col-#{$mq}-offset-#{$i} {
      margin-left: $w;
    }
  }

  /* stylelint-disable-next-line order/order */
  .start-#{$mq} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$mq} {
    justify-content: center;
    text-align: center;
  }

  .end-#{$mq} {
    justify-content: flex-end;
    text-align: end;
  }

  .top-#{$mq} {
    align-items: flex-start;
  }

  .middle-#{$mq} {
    align-items: center;
  }

  .bottom-#{$mq} {
    align-items: flex-end;
  }

  .around-#{$mq} {
    justify-content: space-around;
  }

  .between-#{$mq} {
    justify-content: space-between;
  }

  .first-#{$mq} {
    order: -1;
  }

  .last-#{$mq} {
    order: 1;
  }
}

@each $mq, $w in $mq-breakpoints {
  @if (index(map-keys($mq-breakpoints), $mq) == 1) {
    @include print($mq);
  } @else {
    @media only screen and (min-width: mq-px2em($w)) {
      @include print($mq);
    }
  }
}
