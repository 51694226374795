@import './inter';
@import './freight';
// @import './canela';

html {
  font-size: calc(1em * 0.625);
}

body {
  color: $c-body;
  font-family: $ff-default;
  font-size: $fs-body;
  font-weight: 400;
  line-height: 1.65;

  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xxl: 16px));
  font-weight: 400;
  line-height: 1.75;
}
