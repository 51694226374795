.page-layout {
  margin-top: $header-height;
  padding-top: 3rem;

  @include mq(m) {
    margin-top: $header-height-large;
    padding-top: 8rem;
  }
}

.subpage {
  color: $c-brown;
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
}
