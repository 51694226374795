//Avoid typekit FOUT
html {
  background-color: $c-surface;

  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }

  ::selection {
    background-color: transparentize($color: $c-brown, $amount: 0.25);
    color: $c-beige;
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll {
  overflow-y: hidden;
  height: 100vh;
  padding-right: var(--scrollbar-width, #{$default-scrollbar-width});
}

// VARIOUS
hr {
  height: 1px;
  background-color: $c-skin;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.h-vspacer {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 45px, xxl: 120px));
}

fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
