.show-m {
  display: none;

  @include mq($from: m) {
    display: revert;
  }
}

.hide-m {
  @include mq($from: m) {
    display: none;
  }
}

.o-h {
  overflow: hidden;
}

.v-h {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
