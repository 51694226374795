
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































.socials,
[class*='socials--'] {
  display: flex;
  gap: 2rem;
}

.socials__item {
  svg {
    fill: $c-skin;
    transition: fill 0.15s;
  }

  &:hover {
    svg {
      fill: $c-brown;
    }
  }
}
