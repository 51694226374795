/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * naming: if no idea, check http://chir.ag/projects/name-that-color/
 * order: from dark to light
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-beige: #e8dbd3;
$c-skin: #b49281;
$c-skin-light: #cbaa99;
$c-brown: #4a2623;
$c-green: #274d1e;
$c-surface: $c-beige;
$c-text: $c-brown;
$c-body: $c-text;

/*
* Dimensions
*/
$default-scrollbar-width: 15px;
$content-width-max: mq-get-breakpoint-width(wrapper);
$header-height: 70px;
$header-height-large: 120px;
$header-breakpoint: xl;

/*
* Typography
*/

$ff-default: 'Inter', sans-serif;
$ff-alt: 'Freight', serif;

$fs-base: 10px !default;
$fs-body: 1.5rem;
$fs-body-m: 1.8rem;

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

$header-z-index: 10;

// https://shadows.brumm.af/
$fancy-box-shadow: 0.3px 0.4px 2.2px rgba(0, 0, 0, 0.02),
  0.7px 1px 5.3px rgba(0, 0, 0, 0.028), 1.3px 1.9px 10px rgba(0, 0, 0, 0.035),
  2.2px 3.4px 17.9px rgba(0, 0, 0, 0.042),
  4.2px 6.3px 33.4px rgba(0, 0, 0, 0.05), 10px 15px 80px rgba(0, 0, 0, 0.07);

// Ease out quart
$ease-out: cubic-bezier(0.25, 1, 0.5, 1);
