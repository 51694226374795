
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-title {
  max-width: 800px;
  margin: 0 auto 5rem;
}

.not-found-text {
  max-width: 60rem;
  margin: 0 auto 5rem;
}
