.button {
  position: relative;
  z-index: 1;
  color: $c-brown;
  padding: 1.8rem 3rem;
  border: 1px $c-brown solid;
  text-decoration: none;
  overflow: hidden;
  transition: color 250ms $ease-out, fill 0.25s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    z-index: -1;
    background-color: $c-brown;
    transform: skew(45deg) translate(-130%);
    transition: all 250ms $ease-out;
  }

  &:hover,
  &:focus {
    color: $c-beige;
    fill: $c-beige;

    &::before {
      transform: skew(45deg) translate(-30%);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
