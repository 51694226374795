
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.lang {
  position: relative;

  .home & {
    opacity: 0;
  }
}

.lang__current {
  margin: 0;
  padding: 0;
  color: $c-brown;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &::before {
    content: none;
  }
}

.lang__svg {
  margin-left: 0.25em;
  fill: $c-brown;
}

.lang__nav {
  position: absolute;
  top: 125%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: $fancy-box-shadow;
}

.lang__nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: $c-white;
  border: 1px $c-beige solid;
}

.lang__nav__list__item {
  --progress: 0;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 23px;
    height: 1px;
    background-color: $c-beige;
    transform: translateX(-50%) scaleX(var(--progress));
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

.lang__nav__list__link {
  padding: 11px 19px;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  display: block;
  line-height: 1;
}
