
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































.header {
  --gradient-l: 87;

  position: fixed;
  z-index: $header-z-index;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: $header-height;
  padding: 0;
  color: $c-white;

  .row {
    width: 100%;
  }

  @include mq(m) {
    height: $header-height-large;
    color: $c-brown;
  }

  .no-scroll & {
    padding-right: var(--scrollbar-width, #{$default-scrollbar-width});
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -30%;
    left: 0;
    width: 100%;
    height: 200%;
    transition: all 1s linear;
    pointer-events: none;
    opacity: 0;
    // used online tool to generate gradient -> https://larsenwork.com/easing-gradients/#editor
    // hsl(20, 31%, 87%) === $beige (converted manualy)
    background: linear-gradient(
      to bottom,
      hsl(20, 31%, calc(var(--gradient-l) * 1%)) 0%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.987) 8.1%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.951) 15.5%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.896) 22.5%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.825) 29%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.741) 35.3%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.648) 41.2%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.55) 47.1%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.45) 52.9%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.352) 58.8%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.259) 64.7%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.175) 71%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.104) 77.5%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.049) 84.5%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0.013) 91.9%,
      hsla(20, 31%, calc(var(--gradient-l) * 1%), 0) 100%
    );
  }

  &.scrolled-down {
    &::after {
      opacity: 1;
    }
  }
}

.header__inner {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: currentColor;

  .home & {
    opacity: 0;
  }
}

.header__logo__svg {
  display: block;
  width: 4rem;
  fill: $c-green;

  @include mq(m) {
    width: 6.2rem;
  }
}

.header__contact {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border-width: 0;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.header__contact__label {
  align-items: center;
  display: none;

  @include mq($from: m) {
    display: revert;
  }
}

.header__contact__icon {
  margin-left: 1rem;
  fill: currentColor;
}

.header__contact-sticky-wrapper {
  position: fixed;
  right: col(1, 24);
  top: 8px; // 🪄 Hand tuned
}

.header__contact-sticky {
  --padding: 33px;

  display: flex;
  align-items: center;
  padding: 33px;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  transform: translateX(var(--padding));

  .header__contact__label-wrapper {
    height: 1em;
    position: relative;
    will-change: width;
    overflow: hidden;
  }

  .header__contact__label {
    white-space: nowrap;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .header__contact__icon {
    margin-left: 0;
  }
}

// ::v-deep {
//   [data-animate-onboarding],
//   [data-header-logo] {
//     opacity: 0;
//   }
// }
