
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















::v-deep {
  .line {
    clip-path: inset(-10% -50% 0 -50%);
  }

  .word {
    white-space: nowrap;
  }
}
