.cookiebar {
  /* stylelint-disable-next-line declaration-no-important */
  --cookiebar-c-primary: #{$c-brown} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --cookiebar-c-secondary: #{$c-brown} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --cookiebar-c-secondary-light: #{$c-beige} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --cookiebar-c-gray: #{$c-beige} !important;
}

.cookiebar__title {
  display: block;
  margin: 0;
  margin-bottom: 20px;
  color: var(--cookiebar-c-primary);
  font-size: 32px;
  font-weight: 400;
}

.cookiebar__action,
[class*='cookiebar__action--'] {
  padding: 1rem 1.3rem;
  border-radius: 5px;
}

.cookiebar-controls {
  padding: 4rem;
}
