// Before wrapper mq = 24 available columns
// After wrapper mq = 22 available columns
.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width-max;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width-max} / 24 * 2);
    padding-left: calc(#{$content-width-max} / 24 * 2);
  }
}
